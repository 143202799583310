import React from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Logo } from "../../assets";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <Container>
        <Row classname="align-items-center">
          <Col sm={6}>
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              {" "}
              <img src={Logo} className="footer-img" />
            </Link>
            <p className="footer-p">
              Explore our outstanding collection of butterflies, each reflecting
              a beautiful stage in life. Join our community today and celebrate
              the art of change. You can contact us directly by call or email.
            </p>
          </Col>
          <Col sm={3} className="px-5">
            <h4 className="footer-h4">Quick Links</h4>
            <ul className="footer-ul">
              <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                <li>Home</li>
              </Link>
              <Link to="./Aboutus" onClick={() => window.scrollTo(0, 0)}>
                <li>About Us</li>
              </Link>
            </ul>
          </Col>
          <Col sm={3} className="px-5">
            <h4 className="footer-h4">Quick Links</h4>
            <ul className="footer-ul">
              <a href="https://queenartistry.io/Whitepaper.pdf" target="_blank">
                <li>Whitepaper</li>
              </a>
              <Link to="./Support" onClick={() => window.scrollTo(0, 0)}>
                <li>Support</li>
              </Link>
            </ul>
          </Col>
        </Row>
        <div className="footer-down">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="social-icons">
                {/* <a href='#'><i class="fa-brands fa-facebook-f"></i></a>
                <a href='#'><i class="fa-brands fa-twitter"></i></a> */}
                <a
                  href="https://www.instagram.com/queen_artistry1/"
                  target="_blank"
                >
                  <i class="fa-brands fa-instagram"></i>
                </a>
              </div>
            </Col>
            <Col sm={6}>
              <div className="career-div">
                {/* <a href='#'>Careers</a>
                <a href='#'>Refunds</a>
                <a href='#'>Terms</a> */}
                <p className="footer-p">
                  Design Engineered by Digital Nest Studio © 2024
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
