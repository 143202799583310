import Web3 from 'web3';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import CountdownTimer from '../Assets/Components/CountdownTimer';
// import '../App.css';
// import logo from '../Assets/LOGOHERE.png';
import gif from '../../assets/gif.gif'
// import DialogueBox from '../Assets/Components/DialogueBox';
import { ethers } from "ethers";
import abi from './ABI.json';


function Mint(){


  
const [isConnected, setIsConnected ] = useState (false); 


const contractAddress = '0xc3A8d177F22CADbF15564bc0E41b7c9dFF1c484a'
    const [errorMessage, setErrorMessage] = useState(null);
    const web3 = new Web3(window.ethereum); 
    const [account, setAccount] = useState(localStorage.getItem('walletAddress'));
    const [minted , setMinted] = useState(false);
    
      //Connect Wallet
      const connectWallet = async () => {
        let provider = window.ethereum
        if(!provider){
          alert('Metamask not installed');
          setTimeout(() => {
            setErrorMessage(null);
          }, 2000);
        }
    else{
        provider.request({method: 'eth_requestAccounts'}).then(accounts=>{
          console.log(accounts);
          setIsConnected(true);
          // setAccount(accounts[0])
          localStorage.setItem('walletAddress', accounts[0])
          console.log(account)
        }).catch(error=>{
          console.log(error)
        })
      
       }
      }
      

      


      const mintNow = async () => {

       

        console.log('mint clicked')
        var returnn = false;
        const provider = new ethers.BrowserProvider(window.ethereum)
        // const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = await provider.getSigner();
        const contract = new ethers.Contract(
          contractAddress,
          abi,
          signer
          );
          contract.connect(signer);
          // const contractProvider = new ethers.Contract(
            //   contractAddress,
            //   abi,
            //   provider
            // );
            const signerAddress = await signer.getAddress();
            const balance = await provider.getBalance(signerAddress);
        
        
            // const balance_ether = ethers.utils.formatEther(balance);
        
        
            const mintPrice = '500000000000000'
        // const balanceInEth = ethers.utils.formatEther(
        //     parseFloat(Number(mintPrice)).toString()
        // );
        let overrides = {
          value : mintPrice
          // value: ethers.utils.parseEther(balanceInEth.toString()),
        };
        
        
        //console.log("Error arha hai bois")
        const mintExec = await contract
    .safeMint(signerAddress, overrides)
    .then((minted) => {
        console.log(minted); // Log the result of the minting process
        setMinted(true); // Set minted to true after successful minting
    })
    .catch((error) => {
        // Handle errors if any
        // Errorsetter();
        // Optionally, you can set minted to false here if needed
        // setMinted(false);
        console.error(error); // Log the error
    });
        // setShowSuccess(true);
        setTimeout(() => {
          // setShowSuccess(false);
        }, 5000);
      };


       
    
return(
    <div className='main-root-1'>
        
    <div className='mint-section'>
      {/* <DialogueBox/> */}
          <img src = {gif} className='gif'/>
          {minted ? <>

            <h3>
            Congratulations on being part of the Queen Artistry club. Your NFT has been successfully minted. <br/>
            Visit <a href='https://testnets.opensea.io/'> testnets.opensea.io </a>and connect your wallet to check your newly minted NFT.
          </h3>
          
          </> : 
          <>
           { isConnected ? <>
           
            {<button onClick={mintNow}>  MINT</button> }
            </>
            : 
            <button onClick={connectWallet} >CONNECT WALLET</button>
            }
          
          </>}
          
          

    </div>
     
    </div>
);

}
export default Mint;