import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import { Gallery1, Nft1, Nft2, Gallery2, Gallery3, Roadmap, G1, G2, G3, G4, G5, G6, G7 } from '../../assets';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import { useInView } from 'react-intersection-observer';

function Home() {
    const [isFirstSectionInView, setIsFirstSectionInView] = useState(false);
    const [isSecondSectionInView, setIsSecondSectionInView] = useState(false);

    // Intersection Observer for the first section
    const { ref: firstSectionRef, inView: firstSectionInView } = useInView({
        triggerOnce: true,  // Trigger only once when it enters the viewport
        onChange: (inView) => {
            if (inView) setIsFirstSectionInView(true);
        },
        threshold: 0.1, // Trigger when 50% of the section is in the viewport
    });

    // Intersection Observer for the second section
    const { ref: secondSectionRef, inView: secondSectionInView } = useInView({
        triggerOnce: true,  // Trigger only once when it enters the viewport
        onChange: (inView) => {
            if (inView) setIsSecondSectionInView(true);
        },
        threshold: 0.1,
    });

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/script.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);


    return (
        <>
            {/* First Section */}
            <section
                className={`main1 section ${isFirstSectionInView ? 'animate-typewriter' : ''}`}
                data-bg="#ACDDDE"
                ref={firstSectionRef}
            >
                <Container>
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <h3 className="main1-a">
                                <span className={`${isFirstSectionInView ? 'typewriter-line1 d.block' : 'd-none'}`}>Art That Mimics</span><br />
                                <span className={`${isFirstSectionInView ? 'typewriter-line2 d.block' : 'd-none'}`}>Metamorphosis!</span>
                            </h3>
                            <p className="indie-flower-regular">
                                At Queen Artistry, life is a beautiful journey that brings unique transformative stages. The Queen Artistry Collection welcomes you on a visual journey, reflecting the essence of your life stages through stunning NFT butterflies. Each beautifully produced image represents a stage of your transformation: a whisper from the past, a vibrant blossom from the present, and an optimistic flight into the future.<br /><br />
                                Unlock My Queen Artistry NFT Now!
                            </p>
                            <Button href="#" className="connect-btn">Connect Wallet</Button>
                        </Col>
                        <Col sm={6}>
                            <img src={G5} className="nft1" alt="NFT 1" />
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Second Section */}
            <section
                className={`main2 section ${isSecondSectionInView ? 'animate-typewriter' : ''}`}
                data-bg="#4ec2ff"
                ref={secondSectionRef}
            >
                <Container>
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <img src={Nft2} className="nft2" alt="NFT 2" />
                        </Col>
                        <Col sm={6}>
                            <h3 className="main1-a">
                                <span className={`${isSecondSectionInView ? 'typewriter-line1 d.block' : 'd-none'}`}>Queen Artistry</span><br />
                                <span className={`${isSecondSectionInView ? 'typewriter-line2 d.block' : 'd-none'}`} >Vision:</span>
                            </h3>
                            <p className="indie-flower-regular">
                                At Queen Artistry, our vision is to remind you that change isn't something to fear but to enjoy. It is irresistible whether you welcome it or not. The Queen Artistry collection will inspire and accompany you in every stage of your life journey and destination. Manifesting timeless beauty found in these stages of transformation, we don't just offer gorgeous butterflies, our NFTs portray your present, past, and future aspirations and partnerships. <br /><br />
                                Discover My Journey!
                            </p>
                            <div className="btn-div">
                                <Link to="#" className="connect-btn1">Create Your Account</Link>
                                <Link to="./Aboutus" className="connect-btn1">About NFT</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Other sections */}
            <section className="main3 section" data-bg="#ffe249">
                <Container>
                    <Row>
                        <Col sm={12}>
                            <h3 className="main1-a text-center">ABOUT US</h3>
                            <h3 className="main3-a">Company Profile:</h3>
                            <p className="indie-flower-regular">Queen Artistry is a leading NFT-minting platform whose mission is to turn ordinary moments into classic art. We provide a distinctive collection of digital art with a butterfly theme, as a visual narrative of human development, that shows, each person's journey is a masterpiece in the process. Discover beauty and empowerment by accepting life's inevitable changes at Queen Artistry.</p>
                            <h3 className="main3-a">Know Your Artist:</h3>
                            <p className="indie-flower-regular">A Baltimore native and retired firefighter, Larry M. Talley, Sr. has worn many hats: firefighter, co-owner of a tavern, trucking firm owner, and now, CEO of Spiritually Bound Publishing. Despite no formal training, faith-driven Larry embarked on a music career. His songs echo life experiences and inspiration. A man with multiple passions- music, travel, sports, and photography- Larry finds joy in family life with his wife, their son, and six grandchildren. He lives by the motto, Our purpose of life is to pursue our purpose in life.”</p>
                        </Col>
                        <Col sm={4}></Col>
                        <Col sm={12}>
                            <Splide options={{
                                type: 'loop',
                                perPage: 3,
                                rewind: true,
                                pagination: false,
                                arrows: false,
                                gap: '1rem',
                                pauseOnHover: false,
                                autoScroll: { speed: 1, pauseOnHover: false },
                            }} extensions={{ AutoScroll }}>
                                <SplideSlide><img src={Gallery1} className='gallery1' /></SplideSlide>
                                <SplideSlide><img src={Gallery2} className='gallery1' /></SplideSlide>
                                <SplideSlide><img src={Gallery3} className='gallery1' /></SplideSlide>
                                <SplideSlide><img src={Nft1} className='gallery1' /></SplideSlide>
                                <SplideSlide><img src={G1} className='gallery1' /></SplideSlide>
                                <SplideSlide><img src={G2} className='gallery1' /></SplideSlide>
                                <SplideSlide><img src={G3} className='gallery1' /></SplideSlide>
                                <SplideSlide><img src={G4} className='gallery1' /></SplideSlide>
                                <SplideSlide><img src={G6} className='gallery1' /></SplideSlide>
                                <SplideSlide><img src={G7} className='gallery1' /></SplideSlide>
                                {/* More slides */}
                            </Splide>
                        </Col>
                        <Col sm={12} className='mt-5 mx-auto'>
                            <Button href="https://larrytalleymusic.com/" className='learn-btn1 mx-auto'>Learn More About The Artist</Button>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='main4 section' data-bg="#e96efc">
                <Container>
                    <Row>
                        <Col md={12}>
                            <h3 className="main1-a">ROAD MAP</h3>
                            <img src={Roadmap} className='roadmap mt-4' />
                        </Col>
                    </Row>
                </Container>
            </section>

            <div id="dot"></div>
            <div id="ball"></div>
        </>
    );
}

export default Home;
