import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header/Header';
import Home from './components/Pages/Home';
import Footer from './components/Footer/Footer';
import Support from './components/Pages/Support';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Aboutus from './components/Pages/Aboutus';
import Mint from './components/Pages/Mint';
import '@splidejs/react-splide/css';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route>
            <Route path='/' element={<Home />} />
            <Route path={'/Aboutus'} element={<Aboutus />} />
            <Route path={'/Support'} element={<Support />} />
            {/* <Route path={'/Mint'} element={<Mint />} /> */}
          </Route>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
