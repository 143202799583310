import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';

function Support() {
    return (
        <>
            <section className='whitepaper-1'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <h3 className="whitepaper1-a">Support</h3>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='support-2'>
                <Container>
                    <Row>
                        <Col sm={6}>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>I don’t know much about the NFTs. What are they and how do they work?</Accordion.Header>
                                    <Accordion.Body>
                                    Non-fungible tokens, or NFTs, are similar to electronic ownership certificates for special things, like your gorgeous Queen Artistry in this example! Securing their uniqueness and longevity, each NFT is like a work of art that is kept safe on a blockchain, a virtual ledger. You have the exclusive authority to show off, publish, or even exchange your exclusive piece of art if you possess a Queen Artistry NFT.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>What privileges come with owning a Queen Artistry NFT?</Accordion.Header>
                                    <Accordion.Body>
                                    You are the owner of the digital asset and can display, exchange, or sell it. However, owning an NFT does not automatically give intellectual property rights to the underlying piece of art. For further information, always read the terms attached to each NFT purchase.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>What is the role of Queen Artistry in promoting sustainable NFT practices?</Accordion.Header>
                                    <Accordion.Body>
                                    Queen Artistry is dedicated to encouraging environmentally responsible and sustainable NFT activities. Our platform investigates energy-efficient blockchain alternatives, and we actively look to work with artists who are as committed to ecological responsibility as we are.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col sm={6}>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What happens if I forget my password or can't access my wallet?</Accordion.Header>
                                    <Accordion.Body>
                                    It can be difficult to lose your wallet or forget your password. It's important to keep your healing seed phrase safe. If you run into problems, get in touch with our support team and provide the necessary information, and we'll do everything in our power to help you through the healing process.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Can I sell or exchange my Queen Artistry NFTs on other platforms?</Accordion.Header>
                                    <Accordion.Body>
                                    Yes, you can swap or sell your Queen Artistry NFTs on platforms that work with them. Make sure the platform is compatible with the same blockchain as your NFTs, and stick by their listing and transaction policies.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Can I mint my own NFT on Queen Artistry?</Accordion.Header>
                                    <Accordion.Body>
                                    Making a new NFT is known as minting. At the moment, user minting is not available on Queen Artistry. Skilled artists have curated this collection for you. For now, our portal allows you to browse and buy already-existing NFTs.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Support